@import 'styles/layers.scss';

@layer uikit {
  .root {
    border-radius: 4px;
    width: 100%;
    padding: 12px;
    word-break: break-word;
    font-size: 12px;
    &.orange {
      color: var(--orange1);
      border: 1px solid var(--orange1);
      background-color: var(--orange2);
    }
    &.red {
      color: var(--red5);
      border: 1px solid var(--red5);
      background-color: var(--red4);
    }
    &.white {
      color: var(--white);
      border: 1px solid var(--white);
      background-color: transparent;
    }
    &.yellow {
      color: var(--yellow);
      background-color: var(--yellow1);
    }
  }

  .icon {
    margin: 3px 8px 0 0;
    color: inherit;
    &.orange {
      fill: var(--orange1);
    }
    &.red {
      fill: var(--red5);
    }
    &.white {
      fill: var(--white);
    }
    &.yellow {
      fill: var(--yellow);
    }
  }
}

