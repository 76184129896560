@import 'styles/layers.scss';

@layer component {
  .link {
    color: inherit;
    margin-left: 8px;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }
}